
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../Css/all_style.css';
import { SecureNexusLogo } from '../Navbar/Navbar';
import Chatbot from '../Chatbot/chatbot';

const Footer = () => {
  const [showChatbot, setShowChatbot] = useState(false);

  const year = new Date().getFullYear();

  const toggleChatbot = () => {
    setShowChatbot(!showChatbot);
  };

  return (
    <div>
      <footer className="site-footer">
        <a href="#top" className="use-case-btn back-to-top">Back To Top</a>
        <div className="footer-content">
          <div className="footer-section footer-logo-address">
            {/* <img
              src="img/footer-logo.png"
              alt="SecureNexus Logo"
              className="footer-logo"
            /> */}
            <SecureNexusLogo />

            <p className="footer-logo-address">
              118-120, IJMIMA Complex, Mindspace, Malad (West), Mumbai, 400064
            </p>
            <div className="social-icons">
              <a href="https://www.facebook.com/xbizventures" target="_blank" rel="noopener noreferrer">
                <img src="img/Facebook.png" alt="Facebook" />
              </a>
              <a href="https://twitter.com/securenexus" target="_blank" rel="noopener noreferrer">
                <img src="img/Twitter.png" alt="Twitter" />
              </a>
              <a href="https://www.linkedin.com/company/xbiztechventures/" target="_blank" rel="noopener noreferrer">
                <img src="img/LinkedIn.png" alt="LinkedIn" />
              </a>
              <a href="https://www.youtube.com/@SecureNexus1" target="_blank" rel="noopener noreferrer">
                <img src="img/YouTube.png" alt="Youtube" />
              </a>
            </div>
          </div>
          <div className="footer-section footer-links">
            <h4>Products</h4>
            <ul>
              <li><Link to="/asm">Attack Surface Management</Link></li>
              <li><Link to="/sca">Software Composition Analysis</Link></li>
              <li><Link to="/cspm">Cloud Security Posture Management</Link></li>
              <li><Link to="/tprm">Third Party Risk Management</Link></li>
              <li><Link to="/vm">Vulnerability Management</Link></li>
            </ul>
          </div>
          <div className="footer-section footer-links">
            <h4>Services</h4>
            <ul>
              <li><Link to="/services#cst">Continuous Security Testing</Link></li>
              <li><Link to="/services#ast">Application Security</Link></li>
              <li><Link to="/services#ia">Infrastructure Security</Link></li>
              <li><Link to="/services#csa">Cloud Security</Link></li>
              <li><Link to="/services#tm">Threat Modeling</Link></li>
              <li><Link to="/services#scr">Source Code Reviews</Link></li>
            </ul>
          </div>
          <div className="footer-section footer-links">
            <h4>Resources</h4>
            <ul>
              <li><a href="https://www.xbizventures.com/Career">Careers</a></li>
              <li><a href="/">Blog</a></li>
              <li><Link to="/Services">Services</Link></li>
              <li><Link to="/about">About</Link></li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <div className="footer-bottom-content">
          <p>Copyright © {year} | All Rights Reserved</p>
          <div className="footer-bottom-links">
            Terms and Conditions | <Link to="/privacy_policy">Privacy Policy</Link>
          </div>
        </div>
      </div>

      {/* <div id="chatbot-container">
        {showChatbot ? (
          <Chatbot onMinimize={toggleChatbot} />
        ) : (
          <>
            <div id="chatbot-message">
              <p>
                Experienced a Breach? <br />
                <span style={{ color: "#000" }}>Chat with our Expert, now</span>
              </p>
            </div>
          </>
        )}
            <div id="chatbot-icon" onClick={toggleChatbot}>
              <img className="chatbot-icon" src="img/chatbox.png" alt="Chatbot Icon" />
            </div>
      </div> */}
    </div>
  );
};

export default Footer;
