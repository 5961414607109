import React, { useEffect, useState } from "react";
import "../Css/all_style.css";
import { Link, useLocation } from "react-router-dom";
import Requestdemo from '../Requestdemo/Requestdemo';
import { Topbar } from "../Topbar/Topbar";
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';

export const SecureNexusLogo = () => {
  return (
    <div className="navbar-logo">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src="/img/sec_logo.png" alt="Logo" />
        <div style={{display: 'inline-flex', flexDirection: 'column'}}>
          <div className="logo-title">SECURENEXUS</div>
          <div className="logo-tagline">SECURE THE DIGITAL NEXUS OF FORCES</div>
        </div>
      </div>
    </div>
  );
};

const platformOptions = {
  "asm" : {
    link: "/asm",
    image: "4.svg",
    title: "Full Attack Surface Management",
    desc: "Traditional security tools leave blind spots. SecureNexus ASM provides unwavering vigilance, offering 360° attack surface monitoring.",
  },
  "sca" : {
    link: "/sca",
    image: "5.svg",
    title: "Software Composition Analysis",
    desc: "Protect your code. Review internally for flaws, while Software Composition Analysis identifies risks in third-party components.",
  },
  "cspm" : {
    link: "/cspm",
    image: "7.svg",
    title: "Cloud Security Posture Management",
    desc: "SecureNexus CSPM simplifies security management across various cloud providers, offering seamless visibility into your entire environment.",
  },
  "tprm" : {
    link: "/tprm",
    image: "8.svg",
    title: "Third Party Risk Management",
    desc: "SecureNexus offers a complete solution for vendor risk management, simplifying onboarding and detecting potential risks in supply chains.",
  },
  "vm" : {
    link: "/vm",
    image: "6.svg",
    title: "Vulnerability Management",
    desc: "SecureNexus Vulnerability Management offers a comprehensive solution to proactively detect, prioritize, and fix vulnerabilities throughout your IT infrastructure.",
  },
};

const servicesOptions = {
  "cst" : {
    link: "/services#cst",
    image: "cst.svg",
    title: "Continuous Security Testing",
    desc: "Safeguard your digital assets with our continuous, proactive security testing services, ensuring your systems are always protected.",
  },
  "as" : {
    link: "/services#ast",
    image: "appc.svg",
    title: "Application Security",
    desc: "Defend your applications against threats with our thorough security assessments and expert",
  },
  "is" : {
    link: "/services#ia ",
    image: "Is.svg",
    title: "Infrastructure Security",
    desc: "Ensure your infrastructure's security and resilience with our advanced assessments and expert guidance.",
  },
  "cs" : {
    link: "/services#csa",
    image: "cs.svg",
    title: "Cloud Security",
    desc: "Assess and strengthen the security of your cloud environment with our expert assessment and recommendations.",
  },
  "tm" : {
    link: "/services#tm",
    image: "tm.svg",
    title: "Threat Modeling",
    desc: "Threat modelling, Penetration Testing, Vendor Evaluation, API gateway Security and Governance, DevSecOps Review, Security Workshops.",
  },
  "scr" : {
    link: "/services#scr",
    image: "scr.svg",
    title: "Source Code Review",
    desc: "Detect and address security flaws in your source code, preventing potential vulnerabilities and breaches.",
  },
};

const PlatformOptionItem = ({option, keyname}) => {

  function handleNavModelClick(option) {
    let element;
    
    if(option == "platform") {
      element = document.getElementById('model_platform_div');
    } else {
      element = document.getElementById('model_services_div');
    }

    element.classList.remove("show-mega-menu"); 
  }

  const data = (option == "platform") ? platformOptions[keyname] : servicesOptions[keyname];
  const imgClass = (option == "platform") ? "mega-menu-icon-platform" : "mega-menu-icon-service";

  return(
    <div className="mega-menu-column">
      <Link to={`${data.link}`} onClick={(e)=>{handleNavModelClick(option);}}>
        <div className={`mega-menu-icon ${imgClass}`}>
          <img
            src={`/img/${data.image}`}
            alt="Full Attack Surface Management" 
          />
        </div>
        <div className="mega-menu-content">
          <h4 className="mega-menu-heading">
            {data.title}
          </h4>
          <p className="mega-menu-paragraph">
            {data.desc}
          </p>
          {/* <Link to={`${data.link}`} onClick={(e)=>{handleNavModelClick(option);}} className="learn-more">
            Learn More →
          </Link> */}
          <p className="learn-more">Learn More →</p>
        </div>
      </Link>
    </div>
  );
}

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [isSidebarActive, setSidebarActive] = useState(false);

  const toggleNavbar = () => {
    const popup = document.getElementById("mobile-menu-popup");
    const isPopupOpen = isMenuOpen && popup.style.display !== "none";
  
    setMenuOpen(!isMenuOpen);
  
    if (!isPopupOpen) {
      popup.style.display = "block";
      // Add event listener to close menu when clicking outside
      document.addEventListener("click", closeMenuOutside);
    } else {
      popup.style.display = "none";
      // Remove event listener when closing the menu
      document.removeEventListener("click", closeMenuOutside);
    }
  };
  
const location = useLocation();

  const closeMenuOutside = (event) => {
    const popup = document.getElementById("mobile-menu-popup");
    const hamburgerButton = document.querySelector('.hamburger');
    const openMenuCheck = popup.classList.contains('open');

    if (openMenuCheck 
      && popup.contains(event.target)
      && event.target !== hamburgerButton) {

      // Click occurred outside the menu and not on the hamburger button, close the menu
      if(event.target.id === "mobile_link_platform" || event.target.id  === "mobile_link_services"){
        // do nothing
        // console.log('do nothing');
      } else {
        setMenuOpen(false);
        popup.style.display = "none";
        // Remove event listener
        document.removeEventListener("click", closeMenuOutside);
      }
    }
  };

  const toggleSubMenu = (menu) => {
    setOpenSubMenu(openSubMenu === menu ? null : menu);
  };

  const openSidebar = () => {
    setSidebarActive(true);
  };

  const closeSidebar = () => {
    setSidebarActive(false);
  };

  useEffect(() => {
    const makeSticky = () => {
      const navbar = document.querySelector(".navbar");
      if (window.pageYOffset > 0) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    };

    window.addEventListener("scroll", makeSticky);

    return () => {
      window.removeEventListener("scroll", makeSticky);
    };
  }, []);

  return (
    <div>
      <nav className="navbar">
      <Topbar></Topbar>
      <div className="navbar-container">
        <Link to="/" style={{textDecoration: "none"}}>
            {/* <img src="/img/logo.png" alt="SECURENEXUS Logo" /> */}
            <SecureNexusLogo/>
        </Link>

        <div className="hamburger" onClick={toggleNavbar}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <ul className={`navbar-links`}>
          <li id="model_platform" 
          onMouseOver={() => {document.getElementById('model_platform_div').classList.add('show-mega-menu')}} 
          onMouseLeave={() => {document.getElementById('model_platform_div').classList.remove('show-mega-menu')}} 
          className="menu-item">
          
            <a href="#platform" id="platform-menu" className="menu-item-link">
              Platform
            </a>
            <div id="model_platform_div" className="mega-menu">
              <div className="mega-menu-row">
                <PlatformOptionItem option={"platform"} keyname={"asm"}/>
                <PlatformOptionItem option={"platform"} keyname={"sca"}/>
                <PlatformOptionItem option={"platform"} keyname={"cspm"}/>
              </div>

              <div className="mega-menu-row">
                <PlatformOptionItem option={"platform"} keyname={"tprm"}/>
                <PlatformOptionItem option={"platform"} keyname={"vm"}/>
                <div className="mega-menu-column-nil"></div>
              </div>
            </div>
          </li>
          
          <li id="model_services" 
            onMouseOver={() => {document.getElementById('model_services_div').classList.add('show-mega-menu')}} 
            onMouseLeave={() => {document.getElementById('model_services_div').classList.remove('show-mega-menu')}} 
            className="menu-item">
            <a href="#services" className="menu-item-link">Services</a>
            <div id="model_services_div" className="mega-menu">
              <div className="mega-menu-row">
                <PlatformOptionItem option={"service"} keyname={"cst"}/>
                <PlatformOptionItem option={"service"} keyname={"as"}/>
                <PlatformOptionItem option={"service"} keyname={"is"}/>
              </div>

              <div className="mega-menu-row">
                <PlatformOptionItem option={"service"} keyname={"cs"}/>
                <PlatformOptionItem option={"service"} keyname={"tm"}/>
                <PlatformOptionItem option={"service"} keyname={"scr"}/>
              </div>
            </div>
          </li>
          
          {/* <li>
            <Link to="/" className="menu-item-link">Blog</Link>
          </li> */}
          
          <li>
            <Link to="/About" className="menu-item-link">About</Link>
          </li>
          
          <li>
            <button className="button button-primary" onClick={openSidebar}>
              <span>Request a demo</span>
            </button>
          </li>
        </ul>

        <div
          id="mobile-menu-popup"
          className={`mobile-menu-popup ${isMenuOpen ? "open" : ""}`}  >
          <div
            className="popup-content"
            style={{ backgroundColor: "#191977", width: "100%", marginTop: '60px' }}
          >
            <a
              id="mobile_link_platform"
              href="#platform"
              onClick={() => toggleSubMenu("platform")}
              onMouseEnter={() => toggleSubMenu("platform")} // Add onMouseEnter event
              style={{ color: "white" }}
            >
              Platform
            </a>
            {openSubMenu === "platform" && (
              <div className="platform-submenu">
                <Link to="/asm" style={{ color: "white" }}>
                  Full Attack Surface Management
                </Link>
                <Link to="/sca" style={{ color: "white" }}>
                  Software Composition Analysis
                </Link>
                <Link to="/cspm" style={{ color: "white" }}>
                  Cloud Security Posture Management
                </Link>
                <Link to="/tprm" style={{ color: "white" }}>
                  Third Party Risk Management
                </Link>
                <Link to="/vm" style={{ color: "white" }}>
                  Vulnerability Management
                </Link>
              </div>
            )}

            <a
              id="mobile_link_services"
              href="#services"
              onClick={() => toggleSubMenu("services")}
              onMouseEnter={() => toggleSubMenu("services")} // Add onMouseEnter event
              style={{ color: "white" }}
            >
              Services
            </a>

            {openSubMenu === "services" && (
              <div className="platform-submenu">
                <Link to="/services#cst" style={{ color: "white" }}>
                  Continuous Security Testing
                </Link>
                <Link to="/services#ast" style={{ color: "white" }}>
                  Application Security Testing
                </Link>
                <Link to="/services#ia" style={{ color: "white" }}>
                  Infrastructure Assessment
                </Link>
                <Link to="/services#csa" style={{ color: "white" }}>
                  Cloud Security Assessment
                </Link>
                <Link to="/services#tm" style={{ color: "white" }}>
                  Threat Modeling
                </Link>
                <Link to="/services#scr" style={{ color: "white" }}>
                  Source Code Review
                </Link>
                <Link to="/services#rt" style={{ color: "white" }}>
                  Red Teaming
                </Link>
                <Link to="/services#ca" style={{ color: "white" }}>
                  Cybersecurity Advisory
                </Link>
              </div>
            )}
            

            {(openSubMenu !== "platform" || openSubMenu !== "services") && (
              <>
              
                {/* <Link to="/services" style={{ color: "white" }}>
                  Services
                </Link> */}
            

                <Link to="/" style={{ color: "white" }}>
                  Blog
                </Link>
                <Link to="/About" style={{ color: "white" }}>
                  About
                </Link>
                <button
                  className="use-case-btn"
                  onClick={openSidebar}
                  style={{ color: "white" }}>
                  <span>Request a demo</span>
                </button>
              </>
            )}
          </div>
        </div>
        </div>
      </nav>
      <Requestdemo isActive={isSidebarActive} closeForm={closeSidebar} />
    </div>
  );
};

export default Navbar;
