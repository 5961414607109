import "./Topbar.css";

// export const Topbar = () => {
//   return (
//     <div class="top-bar text-white">
//       <div class="container-xl pt-0 d-flex justify-content-center align-items-center">
//         <div class="d-flex align-items-center gap-2">
//           <div class="icon-wrapper">
//           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20"><path fill="#ffffff" d="M10 0c0.18 0 0.359 0.039 0.523 0.113l7.355 3.122c0.859 0.363 1.5 1.211 1.496 2.235 -0.019 3.875 -1.613 10.965 -8.344 14.188a2.383 2.383 0 0 1 -2.063 0C2.238 16.433 0.645 9.344 0.625 5.468c-0.004 -1.023 0.637 -1.872 1.496 -2.235L9.481 0.113A1.25 1.25 0 0 1 10 0m0 2.609v14.769c5.391 -2.613 6.84 -8.391 6.875 -11.855z"/></svg>
//           </div>
//           <p class="mb-0 text-white text-center bar-content">
//               X-Biz TechVentures is now empaneled by CERT-In as an Information
//               Security Auditing Organization..
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

export const Topbar = () => {
  return (
    <div className="bg-gradient-to-r">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-center flex-wrap">
          <div className="flex align-items-center fade-in">
            <span className="flex p-2 rounded-lg bg-indigo-800">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="h-6 w-6 text-white"
                width="24"
                height="24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75m-3 -7.036A11.96 11.96 0 0 1 3.598 6 12 12 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176 -1.332 9 -6.03 9 -11.622 0 -1.31 -0.21 -2.571 -0.598 -3.751h-0.152c-3.196 0 -6.1 -1.248 -8.25 -3.285"
                />
              </svg>
            </span>
            <p className="ml-3 mb-0 bar-content font-medium text-white">
              X-Biz TechVentures is now empaneled by CERT-In as an Information
              Security Auditing Organization..
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
